import React, { memo } from 'react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { TYPOGRAPHY_36, TYPOGRAPHY_20, TYPOGRAPHY_108_600 } from 'theme/theme'
const Image = dynamic(() => import('components/utils/image'), { ssr: false })

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 116px 0 85px;

  @media (max-width: 1199px) {
    padding-bottom: 125px;
  }
`

const Title = styled.div`
  ${TYPOGRAPHY_36}

  color: #a7a8ad;
`

const SmallCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 284px;
  max-width: 100%;
  height: 216px;
  background: #fff;
  border-radius: 40px;
  padding: 35px 50px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`

const BigCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 790px;
  max-width: 100%;
  min-height: 460px;
  background: #fff;
  border-radius: 40px;
  padding: 50px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    margin-top: 30px;
  }
`

const CardTitle = styled.div`
  ${TYPOGRAPHY_20}

  text-align: ${props => props.left ? 'left' : 'right'};
  color: #646770;

  @media (max-width: 767px) {
    text-align: center;
  }
`

const BigText = styled.div`
  ${TYPOGRAPHY_108_600}

  text-align: right;
  color: #0bf;
  line-height: 70px;
`

const SmallText = styled.div`
  ${TYPOGRAPHY_36}

  text-align: right;
  color: #0bf;
`

const BlockWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const ImgWrap = styled.div`
  display: flex;
  margin: 0 10px 0 10px;
  width: 100%;
  justify-content: center;

  @media (max-width: 999px) {
    ${props => props.mt ? `margin-top: ${props.mt}px;` : ''}
  }
`

const Wrap = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const Wrap2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 999px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const HR = styled.hr`
  border: none;
  height: 1px;
  background: #e2e2e2;
  margin: 15px 0;

  @media (max-width: 767px) {
    width: 75%;
  }
`

const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 0 35px;
  }
`

const SmallCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 40px;

  @media (max-width: 767px) {
    margin-right: unset;
    width: 100%;
  }
`

function AboutUs ({ title }) {
  return (
    <Wrapper>
      <div className="m-auto t-center mb-40">
        <Title data-test="about">{ title }</Title>
      </div>
      <CardsWrapper>
        <SmallCardsWrapper>
          <SmallCard className="mb-30">
            <CardTitle>Years <br/> combined experience:</CardTitle>
            <HR />
            <BlockWrap>
              <BigText>30</BigText>
              <SmallText style={{ alignSelf: 'flex-start' }}>+</SmallText>
            </BlockWrap>
          </SmallCard>
          <SmallCard>
            <CardTitle>Invested:</CardTitle>
            <HR />
            <BlockWrap>
              <SmallText>€</SmallText>
              <BigText>100</BigText>
              <div className="flex d-column">
                <SmallText>+</SmallText>
                <SmallText>m</SmallText>
              </div>
            </BlockWrap>
          </SmallCard>
        </SmallCardsWrapper>
        <BigCard>
          <CardTitle left>Investments</CardTitle>
          <Wrap>
            <Wrap2>
              <ImgWrap>
                <Image src="revolut" type="png" alt="Revolut" width="96px" height="96px" />
              </ImgWrap>
              <ImgWrap mt="20">
                <Image src="eatjust" type="png" alt="Eat Just" width="96px" height="96px" />
              </ImgWrap>
              <ImgWrap mt="20">
                <Image src="darktrace" type="png" alt="Darktrace" width="160px" height="32px" />
              </ImgWrap>
            </Wrap2>

            <div style={{ height: 160 }} />

            <Wrap2>
              <ImgWrap>
                <Image src="investment1" type="png" alt="Depop" width="160px" height="96px" />
              </ImgWrap>
              <ImgWrap>
                <Image src="investment3" type="png" alt="Voodoo" width="167px" height="44px" />
              </ImgWrap>
              <ImgWrap>
                <Image src="investment2" type="png" alt="onfido" width="238px" height="136px" />
              </ImgWrap>
              { /*
              <ImgWrap mt="-20">
                <Image src="investment5" type="png" alt="Bike24" width="182px" height="65px" />
              </ImgWrap>
              <ImgWrap>
                <Image src="investment6" type="png" alt="Touch Surgery" width="205px" height="64px" />
              </ImgWrap>
              */ }
            </Wrap2>
          </Wrap>
        </BigCard>
      </CardsWrapper>
    </Wrapper>
  )
}

export default memo(AboutUs)

AboutUs.propTypes = {
  title: PropTypes.string
}
